import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  useLocation,
  useOutlet,
} from "react-router-dom"

import { createRef, useContext, useEffect } from 'react'
import { createRoot } from 'react-dom/client'

import EnglishApp from './english/AppHookWrapper';
import Top from './Top'
import Correction from './Correction'
import SignUp from "./Auth/SignUp"
import ConfirmSignUp from "./Auth/ConfirmSignUp"
import Login from "./Auth/Login"
import UserProfile from "./UserProfile"
import ForgotPassword from "./Auth/ForgotPassword"
import ResetPassword from "./Auth/ResetPassword"
import Setting from "./Setting"
import RouteGuard from "./Auth/RouteGuard"
import NaviBar from './NaviBar'
import Footer from './Footer'
import TermOfService from "./TermOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import reportWebVitals from './reportWebVitals';
import { AuthProvider, AuthContext } from "./Auth/AuthContext"
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import * as device from "./device";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
}
  from 'mdb-react-ui-kit';

import { sendPageView } from './services/gtm'

const routes = [
  { path: '/', element: <Top />, nodeRef: createRef(), showNavBar: true, transitionPosition: 'left-page' },
  { path: '/english', element: <EnglishApp />, nodeRef: createRef(), showNavBar: true, transitionPosition: 'right-page' },
  { path: '/correction', element: <Correction />, nodeRef: createRef(), showNavBar: true, transitionPosition: 'right-page' },
  { path: '/signUp', element: <SignUp />, nodeRef: createRef(), showNavBar: false },
  { path: '/confirm-sign-up', element: <ConfirmSignUp />, nodeRef: createRef(), showNavBar: false },
  { path: '/confirm-sign-up/:param_email', element: <ConfirmSignUp />, nodeRef: createRef(), showNavBar: false },
  { path: '/confirm-sign-up/:param_email/:param_code', element: <ConfirmSignUp />, nodeRef: createRef(), showNavBar: false },
  { path: '/login', element: <Login />, nodeRef: createRef(), showNavBar: false },
  { path: '/forgot-password', element: <ForgotPassword />, nodeRef: createRef(), showNavBar: false },
  { path: '/reset-password', element: <ResetPassword />, nodeRef: createRef(), showNavBar: false },
  { path: '/reset-password/:param_email', element: <ResetPassword />, nodeRef: createRef(), showNavBar: false },
  { path: '/reset-password/:param_email/:param_code', element: <ResetPassword />, nodeRef: createRef(), showNavBar: false },
  { path: '/term-of-service', element: <TermOfService />, nodeRef: createRef(), showNavBar: true },
  { path: '/privacy-policy', element: <PrivacyPolicy />, nodeRef: createRef(), showNavBar: true },
  { path: '/setting', element: <RouteGuard><Setting /></RouteGuard>, nodeRef: createRef(), showNavBar: true, transitionPosition: 'right-page' },
]

function Content() {
  const location = useLocation()
  const { user } = useContext(AuthContext)
  const currentOutlet = useOutlet()
  const route = routes.find((route) => route.path === location.pathname) ?? {}
  const { nodeRef } = route

  useEffect(() => {
    sendPageView(location.pathname)
  }, [location])

  return (
    <div className="app-panel">
      {route.showNavBar == true &&
        <NaviBar />
      }
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={750}
          classNames={route.transitionPosition}
          unmountOnExit>
          {(state) => (
            <div ref={nodeRef} className={route.transitionPosition}>
              {currentOutlet}
              {!user && device.isIOSApp() === false &&
                <Footer />
              }
            </div>
          )}
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Content />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
  },
])

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  )
}
export default App;
