import {React, useState} from 'react';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBSpinner,
} from 'mdb-react-ui-kit';

import Modal from 'react-bootstrap/Modal'
import Webcam from "react-webcam";
import * as api from "../api"

const WebcamComponent = () => <Webcam />;

export default function CameraDialog(props) {
  const [cameraImage, setCameraImage] = useState(null);
  const [ocrResult, setOcrResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const videoConstraints = {
    // do not specify resolutions. otherwise mobile browsers will return very low resolution
    // width: {min: 3600, ideal: 7200},
    // height: {min: 2400, ideal: 4800},
    facingMode: "environment",
  };

  // const handleDevices = (mediaDevices) => {
  //   mediaDevices.forEach(device => {
  //     console.log(device)
  //     console.log(device.getCapabilities())
  //   })
  // }
  // navigator.mediaDevices.enumerateDevices().then(handleDevices);

  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton className="py-1 m-0">
        <MDBContainer className="m-0">
        <MDBRow center className="h5">答案用紙を撮影します</MDBRow>
        </MDBContainer>
      </Modal.Header>
      <Modal.Body className="p-0 m-0">
      {!cameraImage && (
        <Webcam
          style={{width:"100vw", height:"80vh"}}
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          screenshotQuality={1}
        >
          {({ getScreenshot }) => (
            <div className="d-flex justify-content-end fixed-bottom mb-3">
              <MDBBtn rounded className="green-button mx-3" onClick={() => {
                const imageSrc = getScreenshot();
                // console.log(imageSrc)
                setCameraImage(imageSrc);
              }}>
                <MDBIcon fas icon="camera me-2"/>撮影する
              </MDBBtn>
            </div>
          )}
        </Webcam>
      )}
      {cameraImage && (
        <>
        <div style={{maxWidth:"100vw", height:ocrResult?"45vh":"85vh", overflow:"auto"}} className="text-center align-items-center">
          <img className="img-fluid" src={cameraImage}/>
        </div>
        {ocrResult && (
          <div className="text-center">
            <textarea className="p-3" style={{width:"100vw", minHeight:"45vh"}} value={ocrResult} onChange={(e) => {
              setOcrResult(e.target.value);
            }}/>
          </div>
        )}
        <div className="d-flex justify-content-end mb-3 fixed-bottom">
          <MDBBtn rounded className="green-button" onClick={() => {
            setCameraImage(null);
            setOcrResult(null);
          }}>
            <MDBIcon fas icon="camera me-2"/>撮り直し
          </MDBBtn>
          {!ocrResult && (
            <MDBBtn rounded className="green-button mx-3" onClick={async () => {
              setLoading(true);
              const result = await api.ocr(cameraImage);
              setLoading(false);
              setOcrResult(result);
            }}>
              {loading &&
                <MDBSpinner role='status' className="me-2" style={{ width: '1rem', height: '1rem' }} />
              }
              {!loading &&
                <MDBIcon fas icon="align-left me-2"/>
              }
              文字認識する
            </MDBBtn>
          )}
          {ocrResult && (
            <MDBBtn rounded className="green-button mx-3" onClick={() => {
              if (props.onUseResult) {
                props.onUseResult(ocrResult)
              }}}>
              <MDBIcon fas icon="spell-check me-2"/>
              認識結果を使う
            </MDBBtn>
          )}
        </div>
        </>
      )}
      </Modal.Body>
    </Modal>
  );
}
