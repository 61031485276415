import App from './App';
import { useSearchParams } from 'react-router-dom';

export default function AppHookWrapper() {
  const [searchParams] = useSearchParams();
  const grade = searchParams.get('grade');
  const type = searchParams.get('type');
  const assignment_id = searchParams.get('id');
  const topic = searchParams.get('topic');
  const isSample = searchParams.get('isSample');
  const showCamera = searchParams.get('showCamera');

  return (
    <App
      grade={grade}
      type={type}
      assignment_id={assignment_id}
      topic={topic}
      isSample={isSample}
      showCamera={showCamera}
    />
  );
}
